








import { Component, Vue, Prop } from 'vue-property-decorator';
import { Profile } from 'client-website-ts-library/types/Profile';

import ProfileCard from './ProfileCard.vue';

@Component({
  components: {
    ProfileCard,
  },
})
export default class ProfileCards extends Vue {
  @Prop({ default: [] })
  private readonly profiles!: Profile[];
}
